// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-animation-services-commercial-js": () => import("./../../../src/pages/animation-services/commercial.js" /* webpackChunkName: "component---src-pages-animation-services-commercial-js" */),
  "component---src-pages-animation-services-educational-js": () => import("./../../../src/pages/animation-services/educational.js" /* webpackChunkName: "component---src-pages-animation-services-educational-js" */),
  "component---src-pages-animation-services-explainer-js": () => import("./../../../src/pages/animation-services/explainer.js" /* webpackChunkName: "component---src-pages-animation-services-explainer-js" */),
  "component---src-pages-animation-services-js": () => import("./../../../src/pages/animation-services.js" /* webpackChunkName: "component---src-pages-animation-services-js" */),
  "component---src-pages-animation-services-shorts-js": () => import("./../../../src/pages/animation-services/shorts.js" /* webpackChunkName: "component---src-pages-animation-services-shorts-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-how-we-work-js": () => import("./../../../src/pages/how-we-work.js" /* webpackChunkName: "component---src-pages-how-we-work-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-templates-policy-page-js": () => import("./../../../src/templates/policy-page.js" /* webpackChunkName: "component---src-templates-policy-page-js" */)
}

